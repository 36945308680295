import React from "react"
import styled from '@emotion/styled';
import About from "../../components/about";
import { MainTitle } from "../../components/common/title";
import translate from "../../utils/translation";

const SubTitle = styled.h2`
  margin: 0 0 20px;
  padding: 0;
  font-size: 20px;
  line-height: 1.1;
  color: #2f2f2f;
`;

const Content = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.88;
`;

const Wrapper = styled.section`
  padding-top: 170px;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 990px) {
    padding: 0 24px;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  margin-bottom: 100px;

  @media (max-width: 760px) {
    width: 100%;
    margin-bottom: 60px;
  }

  &:nth-child(even) {
    margin-left: auto;
  }
`;

export default () => <About aboutIndex={1}>
  <Wrapper>
    <Container>
      <ContentContainer>
        <MainTitle>{translate('headers.howToWrite')}</MainTitle>
        <Content>{translate('about.howTo.introDesc')}</Content>
      </ContentContainer>
      <ContentContainer>
        <SubTitle>{translate('about.howTo.streamOfConsTitle')}</SubTitle>
        <Content>{translate('about.howTo.streamOfConsDesc')}</Content>
      </ContentContainer>
      <ContentContainer>
        <SubTitle>{translate('about.howTo.habitsAreHardTitle')}</SubTitle>
        <Content>{translate('about.howTo.habitsAreHardDesc')}</Content>
      </ContentContainer>
    </Container>
  </Wrapper>
</About>;